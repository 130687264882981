import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { commonActions } from 'app/redux/common/commonSlice';
import { setMenuItem } from '_metronic/helpers/CommonHelpers';
// import ClientMonitor from 'skywalking-client-js';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const environment = process.env.REACT_APP_ENVIRONMENT
interface AppInitializerProps {
  children: ReactNode;
}

const casesLocation = {
  'tra-cuu': "tracuu",
  'hop-dong-khach-hang': "hopdong",
  'tham-dinh-tu-dong': "thamdinhtudong",
  'tham-dinh-chu-dong': "thamdinhchudong",
  'thanh-toan': "thanhquyettoan",
  'tien-ich': "tienichtrogiup",
  'thong-ke-bao-cao': "thongkebaocao",
  'quan-tri-he-thong': "quantrihethong",
  'danh-muc': "danhmuc",
  'quyet-toan':"quyettoan"
}

const AppInitializer: React.FC<AppInitializerProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentLocation } = useSelector((state: any) => state.common);
  moment.tz.setDefault("Asia/Ho_Chi_Minh");

  useEffect(() => {
    updateLocation(location.pathname);
    if (process.env.NODE_ENV === "production") {
      console.log = () => { };
    }
    handleMenuInnerWithRedirectLink()
  }, [location.pathname]);

  // useEffect(() => {
  //   try {
  //     ClientMonitor.register({
  //       collector: 'https://a8bbb89fdc8d07abcb547.azinsu.com',
  //       service: `frontend-thamdinh-azinsu-${environment}`,
  //       serviceVersion: 'v1.0.0',
  //     });
  //   } catch (error) {
  //     console.error('Error initializing SkyWalking ClientMonitor:', error);
  //   }
  // }, []);

  const handleMenuInnerWithRedirectLink = () => {
    const pathSplit = location.pathname.split("/");
    const pathSplitSecond = pathSplit[1]
    setMenuItem(casesLocation[pathSplitSecond]);
  };

  const updateLocation = (newLocation: string) => {
    dispatch(commonActions.setPrevLocation(currentLocation));
    dispatch(commonActions.setCurrentLocation(newLocation));
  };

  window.addEventListener('storage', (event) => {
    if (event.key === AUTH_LOCAL_STORAGE_KEY) {
      window.location.reload();
    }
  });

  return <>{children}</>;
};

export default AppInitializer;
