import {call, put, takeLatest} from 'redux-saga/effects'
import {danhSachDoiSoatActions} from './danhSachDoiSoatSlice'
import {handleError} from '_metronic/helpers'
import { getListDanhSachDoiSoatCSKCBService, getListDanhSachDoiSoatDVBHService } from './danhSachDoiSoatService'
import fakeData from './fakeData.json';

function* debounceSearchGetListDanhSachDoiSoatCSKCB(action) {
  yield call(getListDanhSachDoiSoatCSKCBSaga, {payload: action.payload})
}

function* debounceSearchGetListDanhSachDoiSoatDVBH(action) {
  yield call(getListDanhSachDoiSoatDVBHSaga, {payload: action.payload})
}

function* getListDanhSachDoiSoatCSKCBSaga({payload}) {
  try {
    const result = yield call(getListDanhSachDoiSoatCSKCBService, payload)
    if (result) {
      yield put({
        type: danhSachDoiSoatActions.getListDanhSachDoiSoatCoSoKCBSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: danhSachDoiSoatActions.getListDanhSachDoiSoatCoSoKCBFailed.type,
    })
    handleError(e)
  }
}

function* getListDanhSachDoiSoatDVBHSaga({payload}) {
  try {
    const result = yield call(getListDanhSachDoiSoatDVBHService, payload)
    if (result) {
      yield put({
        type: danhSachDoiSoatActions.getListDanhSachDoiSoatDonViBHSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: danhSachDoiSoatActions.getListDanhSachDoiSoatDonViBHFailed.type,
    })
    handleError(e)
  }
}

export default function* danhSachDoiSoatSaga() {
  yield takeLatest(
    danhSachDoiSoatActions.getListDanhSachDoiSoatCoSoKCB.type,
    debounceSearchGetListDanhSachDoiSoatCSKCB
  )
  yield takeLatest(
    danhSachDoiSoatActions.getListDanhSachDoiSoatDonViBH.type,
    debounceSearchGetListDanhSachDoiSoatDVBH
  )
}
