import {removeNullFieldsMedicalRecordCommon} from '_metronic/helpers'
import {api} from 'app/config/api'
import axios from 'axios'

export const getListDanhSachDoiSoatCSKCBService = (data) => {
  const {...otherData} = data
  const objectTemp = {
    ...otherData,
    page: otherData?.page / otherData?.items_per_page,
    maCSKCB: otherData?.maCSKCB?.value,
    ngayThanhToan : otherData?.ngayThanhToan[0] ? [otherData?.ngayThanhToan[0] ? otherData?.ngayThanhToan[0] : null,otherData?.ngayThanhToan[1] ? otherData?.ngayThanhToan[1] : null ] : null
  }
  removeNullFieldsMedicalRecordCommon(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DANH_SACH_DOI_SOAT_CSKCB + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListDanhSachDoiSoatDVBHService = (data) => {
  const {...otherData} = data
  const objectTemp = {
    ...otherData,
    page: otherData?.page / otherData?.items_per_page,
    maCongTyBaoHiem: otherData?.maCongTyBaoHiem?.value,
    ngayThanhToan : otherData?.ngayThanhToan[0] ? [otherData?.ngayThanhToan[0] ? otherData?.ngayThanhToan[0] : null,otherData?.ngayThanhToan[1] ? otherData?.ngayThanhToan[1] : null ] : null
  }
  removeNullFieldsMedicalRecordCommon(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DANH_SACH_DOI_SOAT_DVBH + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
