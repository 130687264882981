import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listDanhSachDoiSoatCoSoKCB: [],
  loadingDanhSachDoiSoatCoSoKCB: true,
  totalDanhSachDoiSoatCoSoKCB: null,
  objectSearchDanhSachDoiSoatCoSoKCB: {
    page: 0,
    items_per_page: 10,
    maCSKCB : null,
    trangThai : null,
    trangThaiThanhToan : null,
    ma : null,
    ngayThanhToan:[null,null]
  },
  objectSearchDanhSachDoiSoatCoSoKCBDefault: {
    page: 0,
    items_per_page: 10,
    maCSKCB : null,
    trangThai : null,
    trangThaiThanhToan : null,
    ma : null,
    ngayThanhToan:[null,null]
  },
  objectSearchDanhSachDoiSoatCoSoKCBFilter: {
    page: 0,
    items_per_page: 10,
    maCSKCB : null,
    trangThai : null,
    trangThaiThanhToan : null,
    ma : null,
    ngayThanhToan:[null,null]
  },
  listDanhSachDoiSoatDonViBH: [],
  loadingDanhSachDoiSoatDonViBH: true,
  totalDanhSachDoiSoatDonViBH: null,
  objectSearchDanhSachDoiSoatDonViBH: {
    page: 0,
    items_per_page: 10,
    maCongTyBaoHiem : null,
    trangThai : null,
    trangThaiHoanQuy : null,
    ma : null,
    ngayThanhToan:[]
  },
  objectSearchDanhSachDoiSoatDonViBHDefault: {
    page: 0,
    items_per_page: 10,
    maCongTyBaoHiem : null,
    trangThai : null,
    trangThaiHoanQuy : null,
    ma : null,
    ngayThanhToan:[]
  },
  objectSearchDanhSachDoiSoatDonViBHFilter: {
    page: 0,
    items_per_page: 10,
    maCongTyBaoHiem : null,
    trangThai : null,
    trangThaiHoanQuy : null,
    ma : null,
    ngayThanhToan:[]
  },
  listChangeDanhSachDoiSoatCSKCB : [],
  hoSoSelectedIdsCSKCB :[],
  firstLoadDataDanhSachCSKCB : [],
  listChangeDanhSachDoiSoatDVBH : [],
  hoSoSelectedIdsDVBH :[],
  firstLoadDataDanhSachDVBH : [],
  tongTienBLVPCSKCB : 0,
  tongTienXacNhanCSKCB : 0,
  tongTienBLVPDVBH : 0,
  tongTienXacNhanDVBH : 0,
  loadingBtnLuuDSCSKCB : false,
  loadingBtnLuuDSDVBH : false,
  loadingBtnGuiDSCSKCB : false,
  loadingBtnGuiDSDVBH : false,
  loadingBtnDongYPopupDS : false
}

export const danhSachDoiSoatSlice = createSlice({
  name: 'danhSachDoiSoat',
  initialState,
  reducers: {
    getListDanhSachDoiSoatCoSoKCB(state, action) {
      state.loadingDanhSachDoiSoatCoSoKCB = true
    },
    getListDanhSachDoiSoatCoSoKCBSuccess(state, action) {
      state.listDanhSachDoiSoatCoSoKCB = action.payload.results
      state.loadingDanhSachDoiSoatCoSoKCB = false
      state.totalDanhSachDoiSoatCoSoKCB = action.payload.total
    },
    getListDanhSachDoiSoatCoSoKCBFailed(state) {
      state.loadingDanhSachDoiSoatCoSoKCB = false
      state.listDanhSachDoiSoatCoSoKCB = []
    },
    unmountGetListDanhSachDoiSoatCSKCB(state){
      state.loadingDanhSachDoiSoatCoSoKCB = true
      state.listDanhSachDoiSoatCoSoKCB = []
    },
    setObjectSearchDanhSachDoiSoatCoSoKCB(state, action) {
      state.objectSearchDanhSachDoiSoatCoSoKCB = {...state.objectSearchDanhSachDoiSoatCoSoKCB, ...action.payload}
    },
    setObjectSearchFilterDanhSachDoiSoatCoSoKCB(state, action) {
      state.objectSearchDanhSachDoiSoatCoSoKCBFilter = {...state.objectSearchDanhSachDoiSoatCoSoKCBFilter, ...action.payload}
    },
    setObjectSearchFilterDanhSachDoiSoatCoSoKCBNoMerge(state, action) {
      state.objectSearchDanhSachDoiSoatCoSoKCBFilter = {...action.payload}
    },
    setObjectSearchFilterDanhSachDoiSoatCoSoKCBDefault(state) {
      state.objectSearchDanhSachDoiSoatCoSoKCBFilter = initialState.objectSearchDanhSachDoiSoatCoSoKCBFilter
    },
    getListDanhSachDoiSoatDonViBH(state, action) {
      state.loadingDanhSachDoiSoatDonViBH = true
    },
    getListDanhSachDoiSoatDonViBHSuccess(state, action) {
      state.listDanhSachDoiSoatDonViBH = action.payload.results
      state.loadingDanhSachDoiSoatDonViBH = false
      state.totalDanhSachDoiSoatDonViBH = action.payload.total
    },
    getListDanhSachDoiSoatDonViBHFailed(state) {
      state.loadingDanhSachDoiSoatDonViBH = false
      state.listDanhSachDoiSoatDonViBH = []
    },
    unmountGetListDanhSachDoiSoatDVBH(state){
      state.loadingDanhSachDoiSoatDonViBH = true
      state.listDanhSachDoiSoatDonViBH = []
    },
    setObjectSearchDanhSachDoiSoatDonViBH(state, action) {
      state.objectSearchDanhSachDoiSoatDonViBH = {...state.objectSearchDanhSachDoiSoatDonViBH, ...action.payload}
    },
    setObjectSearchFilterDanhSachDoiSoatDonViBH(state, action) {
      state.objectSearchDanhSachDoiSoatDonViBHFilter = {...state.objectSearchDanhSachDoiSoatDonViBHFilter, ...action.payload}
    },
    setObjectSearchFilterDanhSachDoiSoatDonViBHNoMerge(state, action) {
      state.objectSearchDanhSachDoiSoatDonViBHFilter = {...action.payload}
    },
    setObjectSearchFilterDanhSachDoiSoatDonViBHDefault(state) {
      state.objectSearchDanhSachDoiSoatDonViBHFilter = initialState.objectSearchDanhSachDoiSoatDonViBHFilter
    },
    setListChangeDanhSachDoiSoatCSKCB(state,action){
      state.listChangeDanhSachDoiSoatCSKCB = [
        ...state.listChangeDanhSachDoiSoatCSKCB.filter(item => 
          !action.payload.some((newItem) => newItem.id === item.id)
        ),
        ...action.payload
      ];
    },
    unmountGetChiTiethDoiSoatCSKCB(state){
      state.listChangeDanhSachDoiSoatCSKCB = []
    },
    setHoSoSelectedIdsCSKCB(state,action){
      state.hoSoSelectedIdsCSKCB = action.payload
    },
    setFirstLoadDataDanhSachCSKCB(state,action){
      state.firstLoadDataDanhSachCSKCB = action.payload
    },
    setListChangeDanhSachDoiSoatDVBH(state,action){
      state.listChangeDanhSachDoiSoatDVBH = [
        ...state.listChangeDanhSachDoiSoatDVBH.filter(item => 
          !action.payload.some((newItem) => newItem.id === item.id)
        ),
        ...action.payload
      ];
    },
    unmountGetChiTiethDoiSoatDVBH(state){
      state.listChangeDanhSachDoiSoatDVBH = []
    },
    setHoSoSelectedIdsDVBH(state,action){
      state.hoSoSelectedIdsDVBH = action.payload
    },
    setFirstLoadDataDanhSachDVBH(state,action){
      state.firstLoadDataDanhSachDVBH = action.payload
    },
    setTongTienBLVPCSKCB(state,action){
      state.tongTienBLVPCSKCB = action.payload
    },
    setTongTienXacNhanCSKCB(state,action){
      state.tongTienXacNhanCSKCB = action.payload
    },
    setTongTienBLVPDVBH(state,action){
      state.tongTienBLVPDVBH = action.payload
    },
    setTongTienXacNhanDVBH(state,action){
      state.tongTienXacNhanDVBH = action.payload
    },
    setLoadingBtnLuuDSCSKCB(state,action){
      state.loadingBtnLuuDSCSKCB = action.payload
    },
    setLoadingBtnLuuDSDVBH(state,action){
      state.loadingBtnLuuDSDVBH = action.payload
    },
    setLoadingBtnGuiDSCSKCB(state,action){
      state.loadingBtnGuiDSCSKCB = action.payload
    },
    setLoadingBtnGuiDSDVBH(state,action){
      state.loadingBtnGuiDSDVBH = action.payload
    },
    setLoadingBtnDongYPopupDS(state,action){
      state.loadingBtnDongYPopupDS = action.payload
    }
  },
})
export const danhSachDoiSoatActions = danhSachDoiSoatSlice.actions

const danhSachDoiSoatReducer = danhSachDoiSoatSlice.reducer

export default danhSachDoiSoatReducer

export const selectDanhSachDoiSoatCSKCB = (state) => state.danhSachDoiSoat.listDanhSachDoiSoatCoSoKCB
export const selectLoadingDanhSachDoiSoatCSKCB = (state) => state.danhSachDoiSoat.loadingDanhSachDoiSoatCoSoKCB
export const selectTotalDanhSachDoiSoatCSKCB = (state) => state.danhSachDoiSoat.totalDanhSachDoiSoatCoSoKCB
export const selectObjectSearchDanhSachDoiSoatCSKCB = (state) => state.danhSachDoiSoat.objectSearchDanhSachDoiSoatCoSoKCB
export const selectObjectSearchDanhSachDoiSoatCSKCBDefault = (state) => state.danhSachDoiSoat.objectSearchDanhSachDoiSoatCoSoKCBDefault
export const selectObjectSearchDanhSachDoiSoatCSKCBFilter = (state) => state.danhSachDoiSoat.objectSearchDanhSachDoiSoatCoSoKCBFilter
export const selectDanhSachDoiSoatDVBH = (state) => state.danhSachDoiSoat.listDanhSachDoiSoatDonViBH
export const selectLoadingDanhSachDoiSoatDVBH = (state) => state.danhSachDoiSoat.loadingDanhSachDoiSoatDonViBH
export const selectTotalDanhSachDoiSoatDVBH = (state) => state.danhSachDoiSoat.totalDanhSachDoiSoatDonViBH
export const selectObjectSearchDanhSachDoiSoatDVBH = (state) => state.danhSachDoiSoat.objectSearchDanhSachDoiSoatDonViBH
export const selectObjectSearchDanhSachDoiSoatDVBHDefault = (state) => state.danhSachDoiSoat.objectSearchDanhSachDoiSoatCoSoKCBDefault
export const selectObjectSearchDanhSachDoiSoatDVBHFilter = (state) => state.danhSachDoiSoat.objectSearchDanhSachDoiSoatDonViBHFilter


