const API_URL = process.env.REACT_APP_API_URL_2

const MINIO_URL = process.env.REACT_APP_MINIO_URL
const CONECT_INPHIEU = process.env.REACT_APP_API_URL_IN_PHIEU
const API_NOTIFICATION_DELIVER = process.env.REACT_APP_API_NOTIFICATION_DELIVER
const API_NOTIFICATION_MANAGEMENT = process.env.REACT_APP_API_NOTIFICATION_MANAGEMENT
const API_NEWS_URL = process.env.REACT_APP_NEWS_SERVICE_URL

const SUPERSET_URL = process.env.REACT_APP_SUPERSET_URL
const ENV = process.env.NODE_ENV
const API_OCR = 'https://dgw-dev.azinsu.com/ocr'
let api = {
  API_OCR,
  MINIO_URL: MINIO_URL,
  SUPERSET_URL: SUPERSET_URL,
  API_TRACE: 'https://1.1.1.1/cdn-cgi/trace',
  ENV: ENV,
  //grant
  API_LOGIN: API_URL + 'grant/login',
  API_LOGIN_V2: API_URL + 'grant/v2/login',
  API_LOGIN_V3: API_URL + 'grant/v3/login',
  API_PERMISSION: API_URL + 'grant/get-permission',

  //auth
  API_CHANGE_PASSWORD: API_URL + 'users/change-password',
  API_USER_INFOR: API_URL + 'users/user-info',
  API_LOG_OUT: API_URL + 'users/logout',
  API_USER_GET_KEY: API_URL + 'users/get-key',

  //category
  API_CATEGORY_ETHNIC: API_URL + 'category/ethnic',
  API_CATEGORY_CAREER: API_URL + 'category/career',
  API_CATEGORY_QUALIFICATION: API_URL + 'category/qualification',
  API_CATEGORY_INSURANCE_TYPE: API_URL + 'category/insurance-type',
  API_CATEGORY_MEDICAL_SUPPLIES: API_URL + 'category/medical-supply',
  API_CATEGORY_MEDICINE_GROUP: API_URL + 'category/medicine-group',
  API_CATEGORY_UNIT_TYPE: API_URL + 'category/unit-type',
  API_CATEGORY_NATION: API_URL + 'category/nations',
  API_CATEGORY_PROVINCE: API_URL + 'category/provinces',
  API_CATEGORY_DISTRICT: API_URL + 'category/districts',
  API_CATEGORY_WARD: API_URL + 'category/wards',
  API_CATEGORY_MEDICINE_USED: API_URL + 'category/medicine-used',
  API_CATEGORY_MEDICINE: API_URL + 'category/medicine',
  API_CATEGORY_MEDICINE_REGISTRATION_NUMBER: API_URL + 'category/medicine-registration-number',
  API_CATEGORY_TECHNICAL_SERVICE: API_URL + 'category/technical-service',
  API_CATEGORY_CSKCB: API_URL + 'category/unit',
  API_CATEGOTY_CSKCB: API_URL + 'category/unit',
  API_ADMINTOOL_CSKCB_EXCEL: API_URL + 'category/unit/uploadExcel',
  API_CATEGORY_DISEASE_CODE: API_URL + 'category/disease-code',
  API_INSURANCE_COMPANY: API_URL + 'category/insurance-companies',
  API_CATEGORY_XML_GROUP_ID: API_URL + 'category/ma-nhom-xml',
  API_ADMINTOOL_CSKCB_EXCEL_GET: API_URL + 'category/unit/getTemplate',
  API_CLEAR_CACHING_ALL: API_URL + 'category/cachingAll',
  API_CATEGORY_XML_GROUP: API_URL + 'category/ma-nhom-xml',
  API_CATEGORY_MEDICAL_EXAMINATION_CODE: API_URL + 'category/ma-loai-kcb',
  API_ADMINTOOL_CSKCB_DETAIL: API_URL + 'category/unit',
  API_CATEGOTY_MA_LOAI_KCB: API_URL + 'category/ma-loai-kcb',
  API_CATEGOTY_LY_DO_TU_CHOI: API_URL + 'category/ly-do-tu-choi',
  API_CATEGORY_DECISION_DIPLOMA: API_URL + 'category/van-bang-quyetdinh',
  API_CATEGORY_RULE: API_URL + 'category/quy-tac',
  API_CATEGORY_REASON: API_URL + 'category/ly-do-tu-choi',
  API_CATEGORY_TYPE_EXPORT_MATH: API_URL + 'category/loai-xuat-toan',
  API_CATEGORY_TYPE_FILE: API_URL + 'category/loai-files',
  API_CATEGORY_TYPE_RULES: API_URL + 'category/kieu-quy-tac',
  API_CATEGORY_MANAGE_CONTRACT: API_URL + 'category/quanly-bh',
  API_CATEGORY_BENEFIT_CONTRACT: API_URL + 'category/nhom-quyenloi-bh',
  API_CATEGORY_ACCIDENT_CODE: API_URL + 'category/ma-tai-nan',
  API_CATEGORY_TYPE_INSURANCE: API_URL + 'category/loai-hinh-bh',
  API_CATEGORY_DEDUCTIBLE_AMOUNT: API_URL + 'category/khoan-khau-tru',
  API_CATEGORY_INSURANCE_OPERATOR: API_URL + 'category/donvi-khaithac-bh',
  API_CATEGORY_BLOCKING_POINT: API_URL + 'category/cac-diem-chan',
  API_CATEGORY_PHAM_VI_DIA_LY: API_URL + 'category/pham-vi-dia-ly',
  API_CATEGORY_DIEU_KIEN_CHUNG: API_URL + 'category/dieu-kien-chung',
  API_INSURANCE_TYPE: API_URL + 'category/loai-hop-dong/display',
  API_CUSTOMER_TYPE: API_URL + 'category/loai-khach-hang/display',
  API_PRODUCT_INSURANCE_TYPE: API_URL + 'category/san-pham-bao-hiem/display',
  API_INSURANCE_COMPANY_MEDIUM: API_URL + 'category/insurance-companies/kieu-bao-hiem/moi-gioi',
  API_INSURANCE_COMPANY_AGENCY: API_URL + 'category/dai-ly/display',
  API_INSURANCE_COMPANY_TPA: API_URL + 'category/insurance-companies/kieu-bao-hiem/tpa',
  API_UNIT_CALCULATOR: API_URL + 'category/don-vi-tinh/display',
  API_UNIT_TIME: API_URL + 'category/don-vi-thoi-gian/display',
  API_BANK_CATEGORY: API_URL + 'category/ngan-hang/display',
  API_INSURED_PERSON_CATEGORY: API_URL + 'category/loai-nguoi-bao-hiem/display',
  API_CATEGORY_DIEU_KIEN_MO_RONG: API_URL + 'category/dieu-kien-mo-rong',
  API_INSURANCE_COMPANY_DISPLAY: API_URL + 'category/insurance-companies/display',
  API_MA_LOAI_KCB_CATEGORY: API_URL + 'category/ma-loai-kcb/display-custom',
  API_QUYEN_LOI_BAO_HIEM_CATEGORY: API_URL + 'category/quyen-loi-bh/display',
  API_INSURED_PERSON_STATUS: API_URL + 'category/trang-thai-nguoi-duoc-bao-hiem/display',
  API_CONFIGURED_PAYMENT_DISPLAY: API_URL + 'category/cau-hinh-de-nghi-tt/paginate',
  API_CONFIGURED_PAYMENT_COMPANY_OFFICERS_DISPLAY:
    API_URL + 'category/cau-hinh-de-nghi-tt/can-bo-cong-ty/display',
  API_CONFIGURED_PAYMENT: API_URL + 'category/cau-hinh-de-nghi-tt',
  API_DOANH_NGHIEP_DISPLAY: API_URL + 'category/doanh-nghiep/display',
  API_CITAD_CATEGORY: API_URL + 'category/citad',
  API_CATEGOTY_DOANH_NGHIEP: API_URL + 'category/doanh-nghiep',
  API_MOI_QUAN_HE_NHAN_VIEN: API_URL + 'category/moi-qh-nhan-vien/display-ma',
  API_CATEGOTY_DAU_MAIL: API_URL + 'category/danh-sach-dau-mail/find-all',
  API_CATEGORY_TYPE_FILE_CONTRACT: API_URL + 'category/cau-hinh-file-ctbh',
  API_CATEGORY_CAU_HINH_DUYET_HO_SO_BOI_THUONG_PAGINATE:
    API_URL + 'category/cau-hinh-duyet-ho-so/paginate',
  API_CATEGORY_CAU_HINH_DUYET_HO_SO_BOI_THUONG: API_URL + 'category/cau-hinh-duyet-ho-so',

  API_CATEGORY_MEDICINE_LIST: API_URL + 'category/thuoc/actions/paginate',
  API_CATEGORY_MEDICINE_LIST_EXPORT: API_URL + 'category/thuoc/actions/export',
  API_CATEGORY_MEDICINE_LIST_IMPORT: API_URL + 'category/thuoc/actions/import',
  API_LY_DO_PHE_DUYET: API_URL + 'category/ly-do-phe-duyet/display',
  //permissions
  API_PERMISSIONS: API_URL + 'permissions',
  API_LIST_PERMISSION_MANAGERMENT_PAGINATE: API_URL + 'permissions/v2/public/permissions/paginated',
  API_PERMISSION_MANAGERMENT: API_URL + 'permissions/v2/public/permissions',
  API_PERMISSION_GROUP_MANAGERMENT: API_URL + 'permissions/v2/public/permission-groups',
  API_PERMISSION_GROUP_MANAGERMENT_PAGINATE:
    API_URL + 'permissions/v2/public/permission-groups/paginated',
  API_CHECK_PERMISSION:
    API_URL + 'permissions/v2/public/permissions/assigned-users/current/validate',
  API_CHECK_PERMISSION_MENU:
    API_URL + 'permissions/v2/public/permissions/assigned-users/current/menu',
  API_USER_MANAGEMENT: API_URL + 'users',
  API_ROLE_TEMPLATE: API_URL + 'permissions/v2/public/role-templates',
  API_LIST_KENHKHAITHACTRUCTIEP: API_URL + 'users/find-by-insurance-company/',

  //manual-assessment
  API_HOSO_KCB: API_URL + 'manual-assessment/ho-so',
  API_DETAILED_MEDICAL_RECORD_DETAILED_AMINISTRATIVE_INFORMATION:
    API_URL + 'manual-assessment/ho-so/hanh-chinh/',
  API_DETATILED_MEDICAL_RECORD_SUBCLINICAL_INDEX: API_URL + 'manual-assessment/ho-so/can-lam-sang/',
  API_DETATILED_MEDICAL_RECORD_DISEASE_PROCESS: API_URL + 'manual-assessment/ho-so/dien-bien-benh/',
  API_DETAILED_MEDICAL_RECORD_ATTACHED_FILE: API_URL + 'manual-assessment/ho-so/dinh-kem/',
  API_DETAILED_MEDICAL_RECORD_HISTORY_MEDICAL_RECORD:
    API_URL + 'manual-assessment/ho-so/lich-su-kcb/',
  API_DETAILED_MEDICAL_RECORD_MEDICAL_EXPENSE: API_URL + 'manual-assessment/ho-so/chi-phi/',
  API_LIST_USER_APPROVED: API_URL + 'users/duyet-ho-so/danh-sach',
  API_SWITCHED_APPROVED: API_URL + 'manual-assessment/ho-so',
  API_HOSO_TTTT: API_URL + 'manual-assessment/ho-so-tttt',
  API_FEEDBACK_EXECUTIVE: API_URL + 'manual-assessment/ly-do-tham-dinh/',
  API_DETAILED_MEDICAL_RECORD_MEDICAL_EXPENSE_V2: API_URL + 'manual-assessment/v2/ho-so/chi-phi/',
  API_HOSO_TTTT_CHECK_COMLETION:
    API_URL + 'manual-assessment/ho-so-tttt/tham-dinh-tu-dong/actions/check-completion',
  API_HOSO_KCB_CHECK_COMLETION:
    API_URL + 'manual-assessment/ho-so/tham-dinh-tu-dong/actions/check-completion',
  API_HOSO_TTTT_FIND_CONTRACT_BY_IDEN:
    API_URL + 'manual-assessment/ho-so-tttt/actions/find-hop-dong',
  API_HOSO_TTTT_FIND_NGUOI_DUOC_BAO_HIEM:
    API_URL + 'manual-assessment/ho-so-tttt/actions/find-nguoi-duoc-bao-hiem',
  API_QUY_TAC_TU_DONG: API_URL + 'manual-assessment/quy-tac-tu-dong',
  API_HAN_MUC: API_URL + 'manual-assessment/ho-so/',
  API_HAN_MUC_HSTTTT: API_URL + 'manual-assessment/ho-so-tttt/',
  API_EXPORT_LICH_SU: API_URL + 'manual-assessment/ho-so-tttt/export-lich-su-ho-so',

  //contract
  API_CONTRACT: API_URL + 'hop-dong',
  API_FIRST_STAGE_CONTRACT: API_URL + 'hop-dong/thong-tin-khach-hang',
  API_DANHSACH_TABLE_BUOC2_HOPDONG: API_URL + 'hop-dong/',
  API_SECOND_STAGE_CONTRACT: API_URL + 'hop-dong/',
  API_DANHSACH_TABLE_BUOC3_HOPDONG: API_URL + 'hop-dong/',
  API_INSURANCE_TERMS_PARENTS_V2: API_URL + 'hop-dong/v2/dieu-khoan-bao-hiem/parents',
  API_INSURANCE_CONTRACT_V2: API_URL + 'hop-dong/v2',
  API_INSURANCE_TERMS_CHILDREN: API_URL + 'hop-dong/dieu-khoan-bao-hiem',
  API_CONTRACT_PEOPLE: API_URL + 'hop-dong/nguoi-duoc-bao-hiem/dieu-khoan-bao-hiem',
  API_DANH_SACH_CONG_TY: API_URL + 'hop-dong/bao-cao-boi-thuong/khach-hang/ten-cong-ty/display',
  API_DANH_SACH_SO_HOP_DONG_BH: API_URL + 'hop-dong/bao-cao-mo-hinh-benh-tat/display/paginated',
  API_HOSO_TTTT_FIND_CONTRACT_BY_MULTI_DATA:
    API_URL + 'hop-dong/nguoi-duoc-bao-hiem/actions/search',
  API_DANH_SACH_SO_HOP_DONG_BH_FILTER_THEO_CONG_TY_BAO_HIEM:
    API_URL + 'hop-dong/danh-sach-so-hdbh/display/paginated',
  API_UPLOAD_FILE_HOP_DONG: API_URL + 'hop-dong/file-dinh-kem',
  API_GET_FILE_DINH_KEM_DIEU_KIEN_BAO_HIEM: API_URL + 'hop-dong/dieu-kien-bao-hiem/file-dinh-kem',
  API_DOWNLOAD_CSKCB_KTTBH_FILE_TEMPLATE:
    API_URL + 'hop-dong/thong-tin-chi-phi/cskcb-khong-thanh-toan/selection-excel-template/path',
  API_DOWNLOAD_CSKCB_BL_FILE_TEMPLATE:
    API_URL + 'hop-dong/thong-tin-chi-phi/cskcb-bao-lanh/selection-excel-template/path',
  //log
  API_LOGS_USER: API_URL + 'log/user-log/filtered',
  API_HISTORY: API_URL + 'log/histories/historyByModule',
  API_PROCESS_RECORD_XML: API_URL + 'log/histories/process-record-by-ho-so-xml',
  API_PROCESS_RECORD_TTTT: API_URL + 'log/histories/process-record-by-ho-so-hoan-tra',
  API_SYSTEM_LOGS: API_URL + 'log/histories/paginate',

  //another
  API_ADMINTOOL_DOCS: API_URL + 'files',
  API_USER: API_URL + 'users',
  API_ROLE: API_URL + 'roles',

  // category-v2 -Team HN
  API_CATEGORY_CO_SO_KCB_V2_UPLOAD_FILE: API_URL + 'bao-cao/file-dinh-kem',
  API_CATEGORY_CO_SO_KCB_V2_DOWNLOAD_FILE: API_URL + 'category-v2/co-so-kcb-v2/download-file',
  API_CATEGORY_CO_SO_KCB_V2_DETAIL: API_URL + 'category-v2/co-so-kcb-v2/get-info',
  API_CATEGORY_CO_SO_KCB_V2_VALID_TT: API_URL + 'category-v2/co-so-kcb-v2/valid-thong-tin-lien-he',
  API_CATEGORY_CO_SO_KCB_V2_SEARCH: API_URL + 'category-v2/co-so-kcb-v2/get-filter',
  //category-v2/search
  API_CATEGORY_DUONG_DUNG_THUOC_SEARCH: API_URL + 'category-v2/duong-dung-thuoc/get-by-filter',
  API_CATEGORY_KIEU_BAO_HIEM_SEARCH: API_URL + 'category-v2/kieu-bao-hiem/get-by-filter',
  API_CATEGORY_CO_SO_KCB_SEARCH: API_URL + 'category-v2/co-so-kcb/get-by-filter',
  API_CATEGORY_TINH_THANH_SEARCH: API_URL + 'category-v2/tinh-thanh/get-by-filter',
  API_CATEGORY_QUOC_GIA_SEARCH: API_URL + 'category-v2/quoc-gia/get-by-filter',
  API_CATEGORY_QUAN_HUYEN_SEARCH: API_URL + 'category-v2/quan-huyen/get-by-filter',
  API_CATEGORY_KIEU_BENH_VIEN_SEARCH: API_URL + 'category-v2/kieu-benh-vien/get-by-filter',
  API_CATEGORY_LOAI_FILE_SEARCH: API_URL + 'category-v2/loai-file/get-by-filter',
  API_CATEGORY_TYPE_XUAT_TOAN_SEARCH: API_URL + 'category-v2/typexuattoan/get-by-filter',
  API_CATEGORY_XA_PHUONG_SEARCH: API_URL + 'category-v2/xa-phuong/get-by-filter',
  API_CATEGORY_MA_LOAI_KCB_SEARCH: API_URL + 'category-v2/ma-loai-kcb/get-by-filter',
  API_CATEGORY_NHOM_QUYENLOI_BH_SEARCH: API_URL + 'category-v2/nhom-quyenloi-bh/get-by-filter',
  API_CATEGORY_ICD_SEARCH: API_URL + 'category-v2/ICD/get-by-filter',
  API_CATEGORY_THUOC_SEARCH: API_URL + 'category-v2/thuoc/get-by-filter',
  API_CATEGORY_THUOCDCSDK_SEARCH: API_URL + 'category-v2/thuoc-duoc-cap-so-dang-ki/get-by-filter',
  API_CATEGORY_VATTUYTE_SEARCH: API_URL + 'category-v2/vat-tu-y-te/get-by-filter',
  API_CATEGORY_DICHVUKYTHUAT_SEARCH: API_URL + 'category-v2/dich-vu-ki-thuat/get-by-filter',
  API_CATEGORY_MATAINAN_SEARCH: API_URL + 'category-v2/ma-tai-nan/get-by-filter',
  API_CATEGORY_VANBANQUYETDINH_SEARCH: API_URL + 'category-v2/van-ban-quyet-dinh/get-by-filter',
  API_CATEGORY_LYDOTUCHOI_SEARCH: API_URL + 'category-v2/ly-do-tu-choi/get-by-filter',
  API_CATEGORY_CONG_TY_BAO_HIEM_SEARCH: API_URL + 'category-v2/cong-ty-bao-hiem/get-by-filter',
  API_CATEGORY_CACDIEMCHAN_SEARCH: API_URL + 'category-v2/cac-diem-chan/get-by-filter',
  API_CATEGORY_DONVIKHAITHACBH_SEARCH: API_URL + 'category-v2/don-vi-khai-thac-bh/get-by-filter',
  API_CATEGORY_KHOANKHAUTRU_SEARCH: API_URL + 'category-v2/khoan-khau-tru/get-by-filter',
  API_CATEGORY_KIEUQUYTAC_SEARCH: API_URL + 'category-v2/kieu-quy-tac/get-by-filter',
  API_CATEGORY_MA_NHOM_XML_SEARCH: API_URL + 'category-v2/ma-nhom-xml/get-by-filter',
  API_CATEGORY_QLBAOHIEM_SEARCH: API_URL + 'category-v2/quan-ly-bh/get-by-filter',
  API_CATEGORY_QUYTAC_SEARCH: API_URL + 'category-v2/quy-tac/get-by-filter',
  API_CATEGORY_LOAI_HINH_BH_SEARCH: API_URL + 'category-v2/loai-hinh-bh/get-by-filter',
  API_CATEGORY_LOAIHINH_BH_SEARCH: API_URL + 'category-v2/loai-hinh-bh/get-by-filter',
  API_CATEGORY_DIEUKIENCHUNG_SEARCH: API_URL + 'category-v2/dieu-kien-chung/get-by-filter',
  API_CATEGORY_DONVITINH_SEARCH: API_URL + 'category-v2/don-vi-tinh/get-by-filter',
  API_CATEGORY_LOAIHOPDONG_SEARCH: API_URL + 'category-v2/loai-hop-dong/get-by-filter',
  API_CATEGORY_LOAIKHACHHANG_SEARCH: API_URL + 'category-v2/loai-khach-hang/get-by-filter',
  API_CATEGORY_PHAMVIDIALY_SEARCH: API_URL + 'category-v2/pham-vi-dia-ly/get-by-filter',
  API_CATEGORY_LOAINGUOIDUOCBAOHIEM_SEARCH:
    API_URL + 'category-v2/loai-nguoi-duoc-bao-hiem/get-by-filter',
  API_CATEGORY_QUYENLOIBAOHIEM_SEARCH: API_URL + 'category-v2/quyenloi-bh/get-by-filter',
  API_CATEGORY_SANPHAMBAOHIEM_SEARCH: API_URL + 'category-v2/san-pham-bh/get-by-filter',
  API_CATEGORY_INSURTPE_GET_ALL: API_URL + 'category-v2/kieu-bao-hiem/get-all',
  API_CATEGORY_QUOC_GIA_GET_ALL: API_URL + 'category-v2/quoc-gia/get-all',
  API_CATEGORY_NGAN_HANG_SEARCH: API_URL + 'category-v2/danh-muc-ngan-hang/get-by-filter',
  API_CATEGORY_TINH_THANH_GET_ALL: API_URL + 'category-v2/tinh-thanh/get-all',
  API_CATEGORY_QUAN_HUYEN_GET_ALL: API_URL + 'category-v2/quan-huyen/get-all',
  API_CATEGORY_XA_PHUONG_GET_ALL: API_URL + 'category-v2/xa-phuong/get-by-filter',
  API_CATEGORY_MA_NHOM_XML_GET_ALL: API_URL + 'category-v2/ma-nhom-xml/get-all',
  API_CATEGORY_DIEU_KIEN_MO_RONG_SEARCH: API_URL + 'category-v2/dieu-kien-mo-rong/get-by-filter',
  API_CATEGORY_CONG_TY_SEARCH: API_URL + 'category-v2/danh-muc-cong-ty/get-by-filter',
  API_CATEGORY_CAUHINH_LOAIFILE_CHO_HOPDONG:
    API_URL + 'category-v2/cau-hinh-danh-muc-loai-file/get-by-filter',

  //category-v2 / add
  API_CATEGORY_MA_LOAI_KCB_CREATE: API_URL + 'category-v2/ma-loai-kcb/create-category',
  API_CATEGORY_DUONG_DUNG_THUOC_CREATE: API_URL + 'category-v2/duong-dung-thuoc/create-category',
  API_CATEGORY_KIEU_BAO_HIEM_CREATE: API_URL + 'category-v2/kieu-bao-hiem/create-category',
  API_CATEGORY_KIEU_BENH_VIEN_CREATE: API_URL + 'category-v2/kieu-benh-vien/create-category',
  API_CATEGORY_ICD_CREATE: API_URL + 'category-v2/ICD/create-category',
  API_CATEGORY_THUOC_CREATE: API_URL + 'category-v2/thuoc/create-category',
  API_CATEGORY_THUOCDCSDK_CREATENEW:
    API_URL + 'category-v2/thuoc-duoc-cap-so-dang-ki/create-category',
  API_CATEGORY_VATTUYTE_CREATE: API_URL + 'category-v2/vat-tu-y-te/create-category',
  API_CATEGORY_DICHVUKYTHUAT_CREATE: API_URL + 'category-v2/dich-vu-ki-thuat/create-category',
  API_CATEGORY_VANBANQUYETDINH_CREATE: API_URL + 'category-v2/van-ban-quyet-dinh/create-category',
  API_CATEGORY_LYDOTUCHOI_CREATE: API_URL + 'category-v2/ly-do-tu-choi/create-category',
  API_CATEGORY_CACDIEMCHAN_CREATE: API_URL + 'category-v2/cac-diem-chan/create-category',
  API_CATEGORY_CONGTYBAOHIEM_CREATE: API_URL + 'category-v2/cong-ty-bao-hiem/create-category',
  API_CATEGORY_DONVIKHAITHACBH_CREATE: API_URL + 'category-v2/don-vi-khai-thac-bh/create-category',
  API_CATEGORY_CONG_TY_BAO_HIEM_CREATE: API_URL + 'category-v2/cong-ty-bao-hiem/create-category',
  API_CATEGORY_KHOANKHAUTRU_CREATE: API_URL + 'category-v2/khoan-khau-tru/create-category',
  API_CATEGORY_KIEUQUYTAC_CREATE: API_URL + 'category-v2/kieu-quy-tac/create-category',
  API_CATEGORY_MA_NHOM_XML_CREATE: API_URL + 'category-v2/ma-nhom-xml/create-category',
  API_CATEGORY_QUYTAC_CREATE: API_URL + 'category-v2/quy-tac/create-category',
  API_CATEGORY_MATAINAN_CREATE: API_URL + 'category-v2/ma-tai-nan/create-category',
  API_CATEGORY_QLBAOHIEM_CREATE: API_URL + 'category-v2/quan-ly-bh/create-category',
  API_CATEGORY_NHOM_QUYENLOI_BH_CREATE: API_URL + 'category-v2/nhom-quyenloi-bh/create-category',
  API_CATEGORY_VAT_TU_Y_TE_CREATE: API_URL + 'category-v2/vat-tu-y-te/create-category',
  API_CATEGORY_DICH_VU_KY_THUAT_CREATE: API_URL + 'category-v2/dich-vu-ki-thuat/create-category',
  API_CATEGORY_LY_DO_TU_CHOI_CREATE: API_URL + 'category-v2/ly-do-tu-choi/create-category',
  API_CATEGORY_TYPEXUATTOAN_CREATE: API_URL + 'category-v2/typexuattoan/create-category',
  API_CATEGORY_LOAI_FILE_CREATE: API_URL + 'category-v2/loai-file/create-category',
  API_CATEGORY_KIEU_QUY_TAC_CREATE: API_URL + 'category-v2/kieu-quy-tac/create-category',
  API_CATEGORY_QUANLY_BH_CREATE: API_URL + 'category-v2/quan-ly-bh/create-category',
  API_CATEGORY_LOAI_HINH_BH_CREATE: API_URL + 'category-v2/loai-hinh-bh/create-category',
  API_CATEGORY_LOAIHINH_BH_CREATE: API_URL + 'category-v2/loai-hinh-bh/create-category',
  API_CATEGORY_DONVITINH_CREATE: API_URL + 'category-v2/don-vi-tinh/create-category',
  API_CATEGORY_CO_SO_KCB_CREATE: API_URL + 'category-v2/co-so-kcb/create-category',
  API_CATEGORY_QUOC_GIA_CREATE: API_URL + 'category-v2/quoc-gia/create-category',
  API_CATEGORY_TINH_THANH_CREATE: API_URL + 'category-v2/tinh-thanh/create-category',
  API_CATEGORY_QUAN_HUYEN_CREATE: API_URL + 'category-v2/quan-huyen/create-category',
  API_CATEGORY_NGAN_HANG_CREATE: API_URL + 'category-v2/danh-muc-ngan-hang/create-category',
  API_CATEGORY_XA_PHUONG_CREATE: API_URL + 'category-v2/xa-phuong/create-category',
  API_CATEGORY_DIEU_KIEN_MO_RONG_CREATE: API_URL + 'category-v2/dieu-kien-mo-rong/create-category',
  API_CATEGORY_CONG_TY_CREATE: API_URL + 'category-v2/danh-muc-cong-ty/create-category',
  API_CATEGORY_CAUHINH_LOAIFILE_CHO_HOPDONG_CREATE_NEW:
    API_URL + 'category-v2/cau-hinh-danh-muc-loai-file/create-category',
  API_CATEGORY_CO_SO_KCB_V2_CREATE: API_URL + 'category-v2/co-so-kcb-v2/create-category',

  // category-v2 // update
  API_CATEGORY_MA_LOAI_KCB_UPDATE: API_URL + 'category-v2/ma-loai-kcb/update-category',
  API_CATEGORY_ICD_UPDATE: API_URL + 'category-v2/ICD/update-category',
  API_CATEGORY_THUOC_UPDATE: API_URL + 'category-v2/thuoc/update-category',
  API_CATEGORY_DUONG_DUNG_THUOC_UPDATE: API_URL + 'category-v2/duong-dung-thuoc/update-category',
  API_CATEGORY_THUOCDCSDK_UPDATE: API_URL + 'category-v2/thuoc-duoc-cap-so-dang-ki/update-category',
  API_CATEGORY_KIEU_BAO_HIEM_UPDATE: API_URL + 'category-v2/kieu-bao-hiem/update-category',
  API_CATEGORY_KIEU_BENH_VIEN_UPDATE: API_URL + 'category-v2/kieu-benh-vien/update-category',
  API_CATEGORY_VAT_TU_Y_TE_UPDATE: API_URL + 'category-v2/vat-tu-y-te/update-category',
  API_CATEGORY_DICH_VU_KY_THUAT_UPDATE: API_URL + 'category-v2/dich-vu-ki-thuat/update-category',
  API_CATEGORY_MA_NHOM_XML_UPDATE: API_URL + 'category-v2/ma-nhom-xml/update-category',
  API_CATEGORY_VANBANQUYETDINH_UPDATE: API_URL + 'category-v2/van-ban-quyet-dinh/update-category',
  API_CATEGORY_QUYTAC_UPDATE: API_URL + 'category-v2/quy-tac/update-category',
  API_CATEGORY_LY_DO_TU_CHOI_UPDATE: API_URL + 'category-v2/ly-do-tu-choi/update-category',
  API_CATEGORY_TYPEXUATTOAN_UPDATE: API_URL + 'category-v2/typexuattoan/update-category',
  API_CATEGORY_LOAI_FILE_UPDATE: API_URL + 'category-v2/loai-file/update-category',
  API_CATEGORY_KIEU_QUY_TAC_UPDATE: API_URL + 'category-v2/kieu-quy-tac/update-category',
  API_CATEGORY_QUANLY_BH_UPDATE: API_URL + 'category-v2/quan-ly-bh/update-category',
  API_CATEGORY_NHOM_QUYENLOI_BH_UPDATE: API_URL + 'category-v2/nhom-quyenloi-bh/update-category',
  API_CATEGORY_MA_TAINAN_UPDATE: API_URL + 'category-v2/ma-tai-nan/update-category',
  API_CATEGORY_LOAI_HINH_BH_UPDATE: API_URL + 'category-v2/loai-hinh-bh/update-category',
  API_CATEGORY_CACDIEMCHAN_UPDATE: API_URL + 'category-v2/cac-diem-chan/update-category',
  API_CATEGORY_KHOAN_KHAUTRU_UPDATE: API_URL + 'category-v2/khoan-khau-tru/update-category',
  API_CATEGORY_DONVIKHAITHACBH_UPDATE: API_URL + 'category-v2/don-vi-khai-thac-bh/update-category',
  API_CATEGORY_DONVITINH_UPDATE: API_URL + 'category-v2/don-vi-tinh/update-category',
  API_CATEGORY_CO_SO_KCB_UPDATE: API_URL + 'category-v2/co-so-kcb/update-category',
  API_CATEGORY_CONG_TY_BAO_HIEM_UPDATE: API_URL + 'category-v2/cong-ty-bao-hiem/update-category',
  API_CATEGORY_QUOC_GIA_UPDATE: API_URL + 'category-v2/quoc-gia/update-category',
  API_CATEGORY_TINH_THANH_UPDATE: API_URL + 'category-v2/tinh-thanh/update-category',
  API_CATEGORY_QUAN_HUYEN_UPDATE: API_URL + 'category-v2/quan-huyen/update-category',
  API_CATEGORY_NGAN_HANG_UPDATE: API_URL + 'category-v2/danh-muc-ngan-hang/update-category',
  API_CATEGORY_XA_PUONG_UPDATE: API_URL + 'category-v2/xa-phuong/update-category',
  API_CATEGORY_DIEU_KIEN_MO_RONG_UPDATE: API_URL + 'category-v2/dieu-kien-mo-rong/update-category',
  API_CATEGORY_CONG_TY_UPDATE: API_URL + 'category-v2/danh-muc-cong-ty/update-category',
  API_CATEGORY_CAUHINH_LOAIFILE_CHO_HOPDONG_UPDATE:
    API_URL + 'category-v2/cau-hinh-danh-muc-loai-file/update-category',
  API_CATEGORY_CO_SO_KCB_V2_UPDATE: API_URL + 'category-v2/co-so-kcb-v2/update-category',
  // category-v2 // delete

  API_CATEGORY_MA_LOAI_KCB_DELETE: API_URL + 'category-v2/ma-loai-kcb/delete-category',
  API_CATEGORY_ICD_DELETE: API_URL + 'category-v2/ICD/delete-category',
  API_CATEGORY_THUOCDCSDK_DELETE: API_URL + 'category-v2/thuoc-duoc-cap-so-dang-ki/delete-category',
  API_CATEGORY_KIEU_BAO_HIEM_DELETE: API_URL + 'category-v2/kieu-bao-hiem/delete-category',
  API_CATEGORY_KIEU_BENH_VIEN_DELETE: API_URL + 'category-v2/kieu-benh-vien/delete-category',
  API_CATEGORY_VAT_TU_Y_TE_DELETE: API_URL + 'category-v2/vat-tu-y-te/delete-category',
  API_CATEGORY_MA_NHOM_XML_DELETE: API_URL + 'category-v2/ma-nhom-xml/delete-category',
  API_CATEGORY_VANBANQUYETDINH_DELETE: API_URL + 'category-v2/van-ban-quyet-dinh/delete-category',
  API_CATEGORY_QUYTAC_DELETE: API_URL + 'category-v2/quy-tac/delete-category',
  API_CATEGORY_LY_DO_TU_CHOI_DELETE: API_URL + 'category-v2/ly-do-tu-choi/delete-category',
  API_CATEGORY_TYPEXUATTOAN_DELETE: API_URL + 'category-v2/typexuattoan/delete-category',
  API_CATEGORY_LOAI_FILE_DELETE: API_URL + 'category-v2/loai-file/delete-category',
  API_CATEGORY_KIEU_QUY_TAC_DELETE: API_URL + 'category-v2/kieu-quy-tac/delete-category',
  API_CATEGORY_QUANLY_BH_DELETE: API_URL + 'category-v2/quan-ly-bh/delete-category',
  API_CATEGORY_NHOM_QUYENLOI_BH_BH_DELETE: API_URL + 'category-v2/nhom-quyenloi-bh/delete-category',
  API_CATEGORY_MA_TAINAN_DELETE: API_URL + 'category-v2/ma-tai-nan/delete-category',
  API_CATEGORY_LOAI_HINH_BH_DELETE: API_URL + 'category-v2/loai-hinh-bh/delete-category',
  API_CATEGORY_CACDIEMCHAN_DELETE: API_URL + 'category-v2/cac-diem-chan/delete-category',
  API_CATEGORY_VATTUYTE_DELETE: API_URL + 'category-v2/vat-tu-y-te/delete-category',
  API_CATEGORY_DICHVUKYTHUAT_DELETE: API_URL + 'category-v2/dich-vu-ki-thuat/delete-category',
  API_CATEGORY_LYDOTUCHOI_DELETE: API_URL + 'category-v2/ly-do-tu-choi/delete-category',
  API_CATEGORY_CONGTYBAOHIEM_DELETE: API_URL + 'category-v2/cong-ty-bao-hiem/delete-category',
  API_CATEGORY_DONVIKHAITHACBH_DELETE: API_URL + 'category-v2/don-vi-khai-thac-bh/delete-category',
  API_CATEGORY_CONG_TY_BAO_HIEM_DELETE: API_URL + 'category-v2/cong-ty-bao-hiem/delete-category',
  API_CATEGORY_KHOANKHAUTRU_DELETE: API_URL + 'category-v2/khoan-khau-tru/delete-category',
  API_CATEGORY_KIEUQUYTAC_DELETE: API_URL + 'category-v2/kieu-quy-tac/delete-category',
  API_CATEGORY_THUOC_DELETE: API_URL + 'category-v2/thuoc/delete-category',
  API_CATEGORY_LOAIHINH_DELETE: API_URL + 'category-v2/loai-hinh-bh/delete-category',
  API_CATEGORY_MATAINAN_DELETE: API_URL + 'category-v2/ma-tai-nan/delete-category',
  API_CATEGORY_QLBAOHIEM_DELETE: API_URL + 'category-v2/quan-ly-bh/delete-category',
  API_CATEGORY_LOAIHINH_BH_DELETE: API_URL + 'category-v2/loai-hinh-bh/create-category',
  API_CATEGORY_DONVITINH_DELETE: API_URL + 'category-v2/don-vi-tinh/delete-category',
  API_CATEGORY_CO_SO_KCB_DELETE: API_URL + 'category-v2/co-so-kcb/delete-category',
  API_CATEGORY_DUONG_DUNG_THUOC_DELETE: API_URL + 'category-v2/duong-dung-thuoc/delete-category',
  API_CATEGORY_QUOC_GIA_DELETE: API_URL + 'category-v2/quoc-gia/delete-category',
  API_CATEGORY_TINH_THANH_DELETE: API_URL + 'category-v2/tinh-thanh/delete-category',
  API_CATEGORY_QUAN_HUYEN_DELETE: API_URL + 'category-v2/quan-huyen/delete-category',
  API_CATEGORY_NGAN_HANG_DELETE: API_URL + 'category-v2/danh-muc-ngan-hang/delete-category',
  API_CATEGORY_XA_PHUONG_DELETE: API_URL + 'category-v2/xa-phuong/delete-category',
  API_CATEGORY_DIEU_KIEN_MO_RONG_DELETE: API_URL + 'category-v2/dieu-kien-mo-rong/delete-category',
  API_CATEGORY_CONG_TY_DELETE: API_URL + 'category-v2/danh-muc-cong-ty/delete-category',
  API_CATEGORY_CAUHINH_LOAIFILE_CHO_HOPDONG_DELETE:
    API_URL + 'category-v2/cau-hinh-danh-muc-loai-file/delete-category',

  // API for đơn vị hành chính
  API_CATEGORY_GET_DISTRICT_BY_PROVICE:
    API_URL + 'category-v2/donvi-hanhchinh/get-district-by-provice',
  API_CATEGORY_GET_PROVINCE: API_URL + 'category-v2/donvi-hanhchinh/get-provinces',
  API_CATEGORY_GET_INFO_INSURANCE_COMPANY: API_URL + 'category-v2/cong-ty-bao-hiem/get-info',
  API_CATEGORY_GET_WARD_BY_DISTRIC: API_URL + 'category-v2/donvi-hanhchinh/get-ward-by-district',
  API_CATEGORY_GET_INFO_CO_SO_KCB: API_URL + 'category-v2/co-so-kcb/get-info',
  API_CATEGORY_DANH_MUC_NGAN_HANG_GET_ALL: API_URL + 'category-v2/danh-muc-ngan-hang/get-all',
  API_CATEGORY_CO_SO_KCB_GET_ALL: API_URL + 'category-v2/co-so-kcb/get-all',
  API_CATEGORY_CONG_TY_BAO_HIEM_GET_ALL: API_URL + 'category-v2/cong-ty-bao-hiem/get-all',

  //FOR DU TRU BAO LANH
  API_ADMINTOOL_DTBL_SEARCH: API_URL + 'hskcb-dutrubaolanh/du-tru-bao-lanh/get-by-filter',
  API_ADMINTOOL_DTBL_GETINFO: API_URL + 'hskcb-dutrubaolanh/du-tru-bao-lanh/get-info',
  API_ADMINTOOL_DTBL_DUYETCHIPHI: API_URL + 'hskcb-dutrubaolanh/du-tru-bao-lanh/duyet-du-tru',
  API_ADMINTOOL_DTBL_DOWNLOADFILE:
    API_URL + 'hskcb-dutrubaolanh/file-dinh-kem/dowload-file-dutru-chiphi',
  API_DTBL_PERMISSION: API_URL + 'hskcb-dutrubaolanh/check-permission/do-check-list-permission',
  API_ADMINTOOL_DLBL_DUYETDUTRUBYIDS:
    API_URL + 'hskcb-dutrubaolanh/du-tru-bao-lanh/duyet-du-tru-by-lst-id',
  API_VIEW_FILE_DTBL: API_URL + 'hskcb-dutrubaolanh/file-dinh-kem/view-file-dutru-chiphi',
  API_ADMINTOOL_DLBL_EXPORT_EXCEL: API_URL + 'hskcb-dutrubaolanh/du-tru-bao-lanh/export-excel',
  API_DTBL_YEU_CAU_BO_SUNG: API_URL + 'hskcb-dutrubaolanh/du-tru-bao-lanh/',
  // download file
  API_DOWNLOAD_FILE_ERROR_BUOC3_HOPDONG: MINIO_URL,
  API_DOWNLOAD_FILE_TEMPLATE: API_URL + 'hop-dong/nguoi-duoc-bao-hiem/template',

  // danh mục cho hợp đồng
  API_CATEGORY_LOAIHOPDONG_CREATE: API_URL + 'category-v2/loai-hop-dong/create-category',
  API_CATEGORY_LOAIHOPDONG_UPDATE: API_URL + 'category-v2/loai-hop-dong/update-category',
  API_CATEGORY_LOAIHOPDONG_DELETE: API_URL + 'category-v2/loai-hop-dong/delete-category',
  API_CATEGORY_LOAIKHACHHANG_CREATE: API_URL + 'category-v2/loai-khach-hang/create-category',
  API_CATEGORY_LOAIKHACHHANG_UPDATE: API_URL + 'category-v2/loai-khach-hang/update-category',
  API_CATEGORY_LOAIKHACHHANG_DELETE: API_URL + 'category-v2/loai-khach-hang/delete-category',
  API_CATEGORY_LOAINGUOIDUOCBAOHIEM_CREATE:
    API_URL + 'category-v2/loai-nguoi-duoc-bao-hiem/create-category',
  API_CATEGORY_LOAINGUOIDUOCBAOHIEM_UPDATE:
    API_URL + 'category-v2/loai-nguoi-duoc-bao-hiem/update-category',
  API_CATEGORY_LOAINGUOIDUOCBAOHIEM_DELETE:
    API_URL + 'category-v2/loai-nguoi-duoc-bao-hiem/delete-category',
  API_CATEGORY_PHAMVYDIALY_CREATE: API_URL + 'category-v2/pham-vi-dia-ly/create-category',
  API_CATEGORY_PHAMVYDIALY_UPDATE: API_URL + 'category-v2/pham-vi-dia-ly/update-category',
  API_CATEGORY_PHAMVYDIALY_DELETE: API_URL + 'category-v2/pham-vi-dia-ly/delete-category',
  API_CATEGORY_QUYENLOIBAOHIEM_CREATE: API_URL + 'category-v2/quyenloi-bh/create-category',
  API_CATEGORY_QUYENLOIBAOHIEM_UPDATE: API_URL + 'category-v2/quyenloi-bh/update-category',
  API_CATEGORY_QUYENLOIBAOHIEM_DELETE: API_URL + 'category-v2/quyenloi-bh/delete-category',
  API_CATEGORY_SANPHAMBAOHIEM_CREATE: API_URL + 'category-v2/san-pham-bh/create-category',
  API_CATEGORY_SANPHAMBAOHIEM_UPDATE: API_URL + 'category-v2/san-pham-bh/update-category',
  API_CATEGORY_SANPHAMBAOHIEM_DELETE: API_URL + 'category-v2/san-pham-bh/delete-category',
  API_CATEGORY_DIEUKIENCHUNG_CREATE: API_URL + 'category-v2/dieu-kien-chung/create-category',
  API_CATEGORY_DIEUKIENCHUNG_UPDATE: API_URL + 'category-v2/dieu-kien-chung/update-category',
  API_CATEGORY_DIEUKIENCHUNG_DELETE: API_URL + 'category-v2/dieu-kien-chung/delete-category',
  // api export, upload file excel
  API_CATEGORY_QLBAOHIEM_DOWNLOAD_TEMPLATE: API_URL + 'category-v2/quyenloi-bh/download-sample',
  API_CATEGORY_QLBAOHIEM_EXPORT_EXCEL: API_URL + 'category-v2/quyenloi-bh/export-excel',
  API_CATEGORY_QLBAOHIEM_IMPORT_EXCEL: API_URL + 'category-v2/quyenloi-bh/import-excel',
  // THANH TOÁN

  // HỒ Sơ Thanh Toán
  API_THANHTOAN_DANH_SACH_HO_SO_THANH_TOAN_SEARCH:
    API_URL + 'thanh-toan/danh-sach-ho-so-thanh-toan/get-by-filter',
  API_THANHTOAN_DANH_SACH_HO_SO_THANH_TOAN_BYPHIEUTT:
    API_URL + 'thanh-toan/phieu-thanh-toan/get-list-ho-so-by-phieu-thanh-toan',
  API_THANHTOAN_XOA_HO_SO_TRONG_PHIEU_THANH_TOAN:
    API_URL + 'thanh-toan/phieu-thanh-toan/xoa-hstt-trong-phieuthanhtoan',
  API_TUCHOI_HO_SO_THANH_TOAN: API_URL + 'thanh-toan/ho-so-thanh-toan/tu-choi-hstt',

  // Phiếu thanh toán
  API_DEFAULT_CHITIET_PTT: API_URL + 'thanh-toan/phieu-thanh-toan/get-default-phieu-thanh-toan',
  API_THANHTOAN_GET_CHI_TIET_NOI_DUNG_PHIEU:
    API_URL + 'thanh-toan/phieu-thanh-toan/get-chi-tiet-noi-dung-phieu',
  //get by filter
  API_THANHTOAN_KE_TOAN_VIEN_SEARCH:
    API_URL + 'thanh-toan/phieu-thanh-toan/get-by-filter-ke-toan-vien',
  API_THANHTOAN_LANH_DAO_KE_TOAN_SEARCH:
    API_URL + 'thanh-toan/phieu-thanh-toan/get-by-filter-lanh-dao-ke-toan',
  API_THANHTOAN_LANH_DAO_CONG_TY_SEARCH:
    API_URL + 'thanh-toan/phieu-thanh-toan/get-by-filter-lanh-dao-cong-ty',
  // kế toán
  API_THANHTOAN_CREATE_PHIEU_THANH_TOAN:
    API_URL + 'thanh-toan/phieu-thanh-toan/tao-phieu-thanh-toan',
  API_THANHTOAN_KE_TOAN_DE_NGHI_PTT:
    API_URL + 'thanh-toan/phieu-thanh-toan/ke-toan-de-nghi-phieu-thanh-toan',
  API_THANHTOAN_KE_TOAN_HUY_PTT:
    API_URL + 'thanh-toan/phieu-thanh-toan/ke-toan-huy-phieu-thanh-toan',
  // lãnh đạo kế toán
  API_THANHTOAN_LANH_DAO_KE_TOAN_DE_NGHI_DUYET_PTT:
    API_URL + 'thanh-toan/phieu-thanh-toan/lanh-dao-ke-toan-de-nghi-duyet',
  API_THANHTOAN_HUY_DUYET_PHIEU_THANH_TOAN:
    API_URL + 'thanh-toan/phieu-thanh-toan/huy-duyet-phieu-thanh-toan',
  // lãnh đạo công ty
  API_THANHTOAN_LANH_DAO_CONG_TY_DE_NGHI_DUYET_CHI_PTT:
    API_URL + 'thanh-toan/phieu-thanh-toan/de-nghi-duyet-chi-phieu-thanh-toan',
  API_THANHTOAN_INPHIEU_TT: API_URL + 'thanh-toan/phieu-thanh-toan/cache-phieuthanhtoan-ftp',
  // permission thanh toán
  API_THANHTOAN_PERMISSION: API_URL + 'thanh-toan/check-permission/do-check-list-permission',

  // category permission
  API_CATEGORY_PERMISSION: API_URL + 'category-v2/check-permission/do-check-list-permission',

  API_THANHTOAN_CONECT: CONECT_INPHIEU + 'PhieuThanhToan/Index',
  // quyết toán
  API_QUYET_TOAN_DOWNLOAD_REPORT:
    API_URL + 'thanh-toan/tong-hop-bao-cao/cache-report-quyettoan-ftp',
  API_QUYET_TOAN_TONG_HOP_BAO_CAO_SAVE_REQUEST:
    API_URL + 'thanh-toan/tong-hop-bao-cao/save-request',
  API_QUYET_TOAN_TONG_HOP_BAO_CAO_GET_TABLE_REQUEST:
    API_URL + 'thanh-toan/tong-hop-bao-cao/get-table-request',
  API_QUYET_TOAN_CONNECT: CONECT_INPHIEU + 'THSLQUYETTOAN/ReportQuyetToan',
  API_QUYET_TOAN_TONG_HOP_BAO_CAO_SEARCH: API_URL + 'thanh-toan/tong-hop-bao-cao/get_by_filter',
  // API THẨM ĐỊNH TỰ ĐỘNG
  API_THAM_DINH_TU_DONG_PROCESSES: API_URL + 'camunda/api/v1/business-processes',

  //API BAO CAO
  API_BAO_CAO: API_URL + 'bao-cao',
  API_BAO_CAO_SAN_PHAM: API_URL + 'bao-cao/san-pham',
  API_BAO_CAO_SAN_PHAM_TOAN_CONG_TY: API_URL + 'bao-cao/boi-thuong/san-pham/toan-cong-ty/xuat-file',
  API_BAO_CAO_SAN_PHAM_THEO_KENH_KHAI_THAC:
    API_URL + 'bao-cao/boi-thuong/san-pham/theo-kenh-khai-thac/xuat-file',
  API_BAO_CAO_KHACH_HANG_THEO_LOAI_LEVEL:
    API_URL + 'bao-cao/boi-thuong/khach-hang/theo-loai-va-level',
  API_BAO_CAO_KHACH_HANG_THEO_GIOI_TINH: API_URL + 'bao-cao/boi-thuong/khach-hang/gioi-tinh',
  API_BAO_CAO_KHACH_HANG_THEO_DO_TUOI: API_URL + 'bao-cao/boi-thuong/khach-hang/do-tuoi',
  API_BAO_CAO_MA_BENH: API_URL + 'bao-cao/mo-hinh-benh-tat/ma-benh',
  API_BAO_CAO_MHBT_CSKCB: API_URL + 'bao-cao/mo-hinh-benh-tat/cskcb',
  API_BAO_CAO_HOP_DONG_TOAN_CONG_TY: API_URL + 'bao-cao/boi-thuong/hop-dong/toan-cong-ty',
  API_BAO_CAO_HOP_DONG_TOAN_CHUNG: API_URL + 'bao-cao/boi-thuong/hop-dong',
  API_BAO_CAO_HOP_DONG_THEO_KENH_KHAI_THAC:
    API_URL + 'bao-cao/boi-thuong/hop-dong/theo-kenh-khai-thac',
  API_BAO_CAO_LOAI_HINH_BOI_THUONG: API_URL + 'bao-cao/boi-thuong/loai-hinh-boi-thuong',
  API_BAO_CAO_LOAI_HINH_BOI_THUONG_DATA: API_URL + 'bao-cao/data/loai-hinh-boi-thuong',
  API_BAO_CAO_LOAI_HINH_BOI_THUONG_DATA_CHART: API_URL + 'bao-cao/data-chart/loai-hinh-boi-thuong',
  API_BAO_CAO_LOAI_HINH_BOI_THUONG_EXCEL: API_URL + 'bao-cao/loai-hinh-boi-thuong',
  API_BAO_CAO_QUYEN_LOI_BAO_HIEM_EXCEL: API_URL + 'bao-cao/quyen-loi-bao-hiem',
  API_BAO_CAO_QUYEN_LOI_BAO_HIEM_DATA: API_URL + 'bao-cao/data/quyen-loi-bao-hiem',
  API_BAO_CAO_CO_SO_KCB_EXCEL: API_URL + 'bao-cao/co-so-kcb',
  API_BAO_CAO_CO_SO_KCB_DATA: API_URL + 'bao-cao/data/co-so-kcb-top10-so-tien',
  API_BAO_CAO_CO_SO_KCB_DATA_2: API_URL + 'bao-cao/data/co-so-kcb-top10-ho-so',
  API_BAO_CAO_HO_SO_DA_DUYET_EXCEL: API_URL + 'bao-cao/ho-so-voi-yeu-cau',
  API_BAO_CAO_HO_SO_DA_DUYET_DATA: API_URL + 'bao-cao/data/ho-so-voi-yeu-cau',
  API_BAO_CAO_HO_SO_DA_DUYET_DATA_CHART: API_URL + 'bao-cao/data-chart/ho-so-voi-yeu-cau',
  API_BAO_CAO_NHOM_BENH_DATA: API_URL + 'bao-cao/data/nhom-benh',
  API_BAO_CAO_NHOM_BENH_EXCEL: API_URL + 'bao-cao/nhom-benh',
  API_BAO_CAO_HOP_DONG_EXCEL: API_URL + 'bao-cao/hop-dong',
  API_BAO_CAO_HOP_DONG_DATA: API_URL + 'bao-cao/data/hop-dong',
  API_BAO_CAO_YEU_CAU_BOI_THUONG_BAO_HIEM_EXCEL: API_URL + 'bao-cao/thong-tin-yeu-cau-boi-thuong',
  API_BAO_CAO_CHUONG_TRINH_BAO_HIEM_EXCEL: API_URL + 'bao-cao/chuong-trinh-bao-hiem',
  API_BAO_CAO_CHUONG_TRINH_BAO_HIEM_DATA: API_URL + 'bao-cao/data/chuong-trinh-bao-hiem',
  API_BAO_CAO_TU_CHOI_TOAN_BO_EXCEL: API_URL + 'bao-cao/tu-choi-toan-bo-bh',
  API_BAO_CAO_TU_CHOI_TOAN_BO_DATA: API_URL + 'bao-cao/data/tu-choi-toan-bo-bh',
  API_BAO_CAO_TU_CHOI_TOAN_BO_LY_DO_EXCEL: API_URL + 'bao-cao/tu-choi-toan-bo-ly-do',
  API_BAO_CAO_TU_CHOI_TOAN_BO_LY_DO_DATA: API_URL + 'bao-cao/data/tu-choi-toan-bo-ly-do',
  API_BAO_CAO_TU_CHOI_MOT_PHAN_GOI_BAO_HIEM_EXCEL: API_URL + 'bao-cao/tu-choi-mot-phan-bao-hiem',
  API_BAO_CAO_TU_CHOI_MOT_PHAN_GOI_BAO_HIEM_DATA:
    API_URL + 'bao-cao/data/tu-choi-mot-phan-bao-hiem',

  API_BAO_CAO_QUYEN_LOI_BAO_HIEM: API_URL + 'bao-cao/boi-thuong/quyen-loi-bao-hiem',
  API_BAO_CAO_DANH_SACH_HO_SO: API_URL + 'bao-cao/ho-so/danh-sach-ho-so',
  API_BAO_CAO_HO_SO_THANH_TOAN: API_URL + 'thanh-toan/bao-cao-danh-sach-ho-so/save-request',
  API_URL_BAO_CAO_HO_SO_THANH_TOAN: CONECT_INPHIEU + 'BAOCAO_DANHSACHHOSO/ReportDanhSachHoSo',
  API_THONG_KE_TAI_KHOAN: 'https://payment.azinsu.com/reconciliation/export/summary',
  // download file xml
  API_DOWNLOAD_FILE_XML: API_URL + 'hskcb-dutrubaolanh/file-dinh-kem/download-file-hskcb',

  // notification management
  API_NOTIFICATION_NOTIFICATION_TYPE: API_NOTIFICATION_MANAGEMENT + 'notitype',
  API_NOTIFICATION_NOTIFICATION_TEMPLATE: API_NOTIFICATION_MANAGEMENT + 'template',
  API_NOTIFICATION_NOTIFICATION_HISTORY: API_NOTIFICATION_MANAGEMENT + 'notification-status',
  // notification deliver
  API_NOTIFICATION_DELIVER_CLIENT: API_NOTIFICATION_DELIVER + 'notificfg/client',
  API_NOTIFICATION_DELIVER_EMAIL_SMTP: API_NOTIFICATION_DELIVER + 'notificfg/smtp',
  API_NOTIFICATION_DELIVER_EMAIL_API: API_NOTIFICATION_DELIVER + 'notificfg/emailapi',
  API_NOTIFICATION_DELIVER_SMS: API_NOTIFICATION_DELIVER + 'notificfg/sms',
  API_NOTIFICATION_DELIVER_LOG: API_NOTIFICATION_DELIVER + 'notificfg/statistic',
  // claim settlement
  API_DANHSACH_HOSO_THANHTOAN: API_URL + 'thanh-quyet-toan/ho-so-thanh-toan/paginate',
  API_DANHSACH_PHIEU_THANHTOAN: API_URL + 'thanh-quyet-toan/phieu-thanh-toan/paginate',
  API_TAO_PHIEU_THANHTOAN: API_URL + 'thanh-quyet-toan/phieu-thanh-toan',
  API_SELECT_ALL_BY_FILTER_HO_SO_THANH_TOAN:
    API_URL + 'thanh-quyet-toan/ho-so-thanh-toan/select-all-by-filter',
  API_PHIEU_THANHTOAN: API_URL + 'thanh-quyet-toan/phieu-thanh-toan/',
  API_HO_SO_THANH_TOAN_PHIEU_ID: API_URL + 'thanh-quyet-toan/ho-so-thanh-toan/by-phieu-id/',
  API_HO_SO_THANH_TOAN_HOA_DON: API_URL + 'thanh-quyet-toan/ho-so-thanh-toan/hoa-don',
  API_PHIEU_THANH_TOAN_HUY_DUYET: API_URL + 'thanh-quyet-toan/phieu-thanh-toan/huy-duyet',
  API_PHIEU_THANH_TOAN_DUYET: API_URL + 'thanh-quyet-toan/phieu-thanh-toan/duyet',
  API_EXPORT_DANH_SACH_HO_SO_THANH_TOAN: API_URL + 'thanh-quyet-toan/ho-so-thanh-toan/export',
  API_EXPORT_PHIEU_THANH_TOAN: API_URL + 'thanh-quyet-toan/phieu-thanh-toan/export',
  API_SELECT_ALL_BY_FILTER_PHIEU_THANH_TOAN:
    API_URL + 'thanh-quyet-toan/phieu-thanh-toan/select-all-by-filter',
  API_PHIEU_THANH_TOAN_TU_CHOI: API_URL + 'thanh-quyet-toan/phieu-thanh-toan/tu-choi',
  API_PAGINATE_TAI_KHOAN_CHI_TRA: API_URL + 'thanh-quyet-toan/tai-khoan-chi-tra/paginate',
  API_COMMON_TAI_KHOAN_CHI_TRA: API_URL + 'thanh-quyet-toan/tai-khoan-chi-tra',
  API_QUAN_LY_TRUYEN_NHAN_DU_LIEU: API_URL + 'thanh-quyet-toan/lich-su-giao-dich',
  API_GET_THE_BAO_HIEM: API_URL + 'hop-dong/nguoi-duoc-bao-hiem/url-the-bao-hiem',
  API_SEND_THE_BAO_HIEM: API_URL + 'hop-dong/send-mail-the-bao-hiem',
  API_CDN: 'https://cdn.azinsu.com/',
  API_TRUY_VAN_GIAO_DICH: API_URL + 'thanh-quyet-toan/lich-su-giao-dich',
  API_TRUY_VAN_GIAO_DICH_DON: API_URL + 'thanh-quyet-toan/lich-su-giao-dich/single-inquiry',
  API_TRUY_VAN_GIAO_DICH_LO: API_URL + 'thanh-quyet-toan/lich-su-giao-dich/multi-inquiry',
  API_TRUY_VAN_NHIEU_GIAO_DICH:
    API_URL + 'thanh-quyet-toan/lich-su-giao-dich/inquiry-list-transactions',
  API_VIRTUAL_ACCOUNT: API_URL + 'thanh-quyet-toan/tai-khoan-chi-tra/virtual-account',
  // medical-claims-reconciliation-service
  API_DANH_SACH_DOI_SOAT_CSKCB: API_URL + 'ky-doi-soat/co-so-kcb',
  API_KY_DOI_SOAT_CSKCB: API_URL + 'ky-doi-soat/co-so-kcb',
  API_DANH_SACH_DOI_SOAT_DVBH: API_URL + 'ky-doi-soat/cong-ty-bao-hiem',
  API_KY_DOI_SOAT_DVBH: API_URL  + 'ky-doi-soat/cong-ty-bao-hiem',

  // Configure news and events
  API_NEWS: API_NEWS_URL + 'news',
  API_NEWS_UPLOAD_FILE: API_NEWS_URL + 'news/upload-image',
  API_NEWS_UPLOAD_AVATAR: API_NEWS_URL + 'news/upload-avatar',
  API_NEWS_EXPORT: API_NEWS_URL + 'news/export',
  // dashboard
  API_DASHBOARD: API_URL + 'bao-cao/dashboard',
}

export { api }
